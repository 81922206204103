import React from 'react'
// import PortableText from "react-portable-text"
import BlockContent from '@sanity/block-content-to-react'
import getYouTubeID from 'get-youtube-id'

import Link from "./link";
import TextWithIcon from "./textWithIcon";

const BlockRenderer = (props) => {
  const {style = 'normal'} = props.node;
  
  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    return <div className="columns">
      <div 
        className={`column is-offset-1 is-7`}
      >
        {React.createElement(style, { className: `title is-${level} mb-0`}, props.children)}
      </div>
    </div>
  }

  if (style === 'blockquote') {
    return <blockquote>- {props.children}</blockquote>
  }
  
  // Fall back to default handling
  return <div className="columns">
    <div className={`column is-7 is-offset-1 py-0`}>
      {BlockContent.defaultSerializers.types.block(props)}
    </div>
  </div>
}

const serializers = {
  types: {
    vimeo: (props) => {

      const url = props.node.url

      const vimeoVideoId = url && url
          .split('/')[url.split('/').length - 1]
      
      if (!url) {
          return <div>Missing Vimeo URL</div>    
      }
      return ( 
        <figure className="image is-16by9 mb-6">

          <iframe
              className="has-ratio"
              width="560" 
              height="315" 
              src={`https://player.vimeo.com/video/${vimeoVideoId}`} 
              title="Vimeo video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
          </iframe>

        </figure>
      )
    },
    youtube: (props) => { 
      
      const id = getYouTubeID(props.node.url)
      const videourl = `https://www.youtube.com/embed/${id}`

      if (!id) {
        return <div>Missing YouTube URL</div>    
      }
      return (
        <div className="columns">
          <div className={`column is-offset-1 is-10 py-0`}>

            <figure className="image is-16by9">
              <iframe
                className="has-ratio"
                title="YouTube Preview" 
                width="560" 
                height="315" 
                src={videourl} 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </figure>

          </div>
        </div>
      )
    },
    block: BlockRenderer,
    link: ({node}) => {
      return (
        <div className="columns">
          <div className={`column is-offset-1 is-8 py-0`}>
            <Link 
              externalURL={node.externalURL}
              linkText={node.linkText}
              arrow
              bold
              underlined
            />
          </div>
        </div>
      )
    },
    textWithIcon: (props) => {
      return (
        <div className="columns">
          <div className={`column is-offset-1 is-8 py-0`}>
            <div className="columns">
              <TextWithIcon horizonal iconRight _rawRichText={props.node.richText} {...props}
                mainImage={props.node.mainImage && props.node.mainImage.asset && 
                  {
                    "asset": {
                      "fixed": {
                        "src": `https://cdn.sanity.io/images/8njkenaf/production/${props.node.mainImage.asset._ref.replace('-svg', '.svg').replace('image-', '')}`
                      }
                    }
                  }
                }
              />
            </div>
          </div>
        </div>
      )
    },
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

const RichText = ({ portableTextContent, textWidth }) => (
  <BlockContent 
    blocks={portableTextContent} 
    serializers={serializers} 
    textWidth={textWidth}
    projectId="8njkenaf"
    dataset="production"
  />
)

export default RichText;

// https://www.sanity.io/plugins/react-portable-text