import React from "react";
import { css } from '@emotion/css';
import LinkComponent from "./link";
import { breakpoints } from "../../styling/breakpoints";

const quoteStyling = css`

  .quote-wrap {
    display: flex;
    @media only screen and (${breakpoints.mobile}) {
      flex-direction: column;
    }
  }

  .image {
    @media only screen and (${breakpoints.mobile}) {
      order: -1;
    }
  }

  .quote-text {
    margin-bottom: 1em;
    font-weight: 600;
    font-style: italic;
    font-size: 24px;
  }

  .author {
    width: 100%;
    display: block;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    font-size: 24px;
  }

  .author-title {
    font-style: normal;
    width: 100%;
    display: block;
  }

`

const maybeImage = image => {
  let img = null;
  if (image && image.asset && image.asset.fixed && image.asset.fixed.src) {
    img = (
      <img width="530" height="312" src={image.asset.fixed.src} alt={image.alt ? image.alt : ''} />
    );
  }
  return img;
};

const Cite = (props) => (
  <div className="mb-4">
    <cite>
      <span className="author block ">{props.author}</span>
      <em className="author-title block m-0">{props.title_1}</em>
      <em className="author-title m-0">{props.title_2}</em>
    </cite>
  </div>
)

const QuoteText = (props) => (
  <div className={`column is-4 is-offset-1`}>
    
    {props.cite}

    <div className="quote-text">"{props.quote}"</div>

    {props.story &&
      <div className="is-flex is-justify-content-flex-end">
        {<LinkComponent slug={'/artikler/' + props.story.slug.current} linkText="Les mer" chevron />}
      </div>
    }

  </div>
)

function Quote (props) {
  const img = maybeImage(props.image);

  return (
    <section className="column py-2">

      <div className="container ">

        <div className="section">

          {props.i % 2 
            ? <blockquote>

                <div 
                  className={`columns is-variable is-vcentered is-align-items-center ${quoteStyling}`}>
                  
                  <div className="quote-wrap">

                    <QuoteText {...props} cite={
                      <Cite {...props} />
                    } />

                    <div className={`column is-5 is-offset-1 image`}>{img}</div>

                  </div>

                </div>

              </blockquote>
            : <blockquote>

                <div 
                  className={`columns is-variable is-vcentered is-align-items-center ${quoteStyling}`}>

                    <div className="quote-wrap">

                      <div className={`column is-5 is-offset-1 image`}>{img}</div>

                      <QuoteText {...props} cite={
                        <Cite {...props} />
                      } />

                    </div>

                </div>

              </blockquote>
          }

        </div>
        
      </div>

    </section>
  )
}

export default Quote;
