import React from "react";
import {css} from '@emotion/css';

import PortableTextWrap from "./portableTextWrap";
import Body from "./body";

import LinkComponent from "./link";

import { breakpoints } from "../../styling/breakpoints";
import "../../styling/libraries.scss"

const maybeVideo = videourl => {
  let video = null;
  if (videourl) {

  const isYouTubeVideo = videourl && videourl
    .split('/')
    .join()
    .split('.')
    .indexOf('youtube') > -1

  const isVimeoVideo = videourl && videourl
    .split('/')
    .join()
    .indexOf('vimeo.com') > -1

  const vimeoVideoId = videourl && videourl
    .split('/')[videourl.split('/').length - 1]

  const youtubeVideoId = videourl && videourl
    .split('/')
    .join()
    .split('=')[videourl.split('/').join().split('=').length - 1]


    video = (
      <div>

        {videourl &&

        <div className="mb-3 auto-resizable-iframe">

          <figure className="image is-16by9">
            
            <iframe 
              className="has-ratio"
              src={isVimeoVideo 
                ? `https://player.vimeo.com/video/${vimeoVideoId}`
                : isYouTubeVideo 
                  ? `https://www.youtube.com/embed/${youtubeVideoId}`
                  : null}
              title='Video'
              width="485" 
              height="292" 
              frameBorder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen
            />

          </figure>

        </div>

        }        
      </div>
    )
  }
  return video;
}

const maybeImage = mainImage => {
  let img = null;
  if (mainImage && mainImage.asset) {
    img = (
      <figure 
        style={{
          'width': mainImage.width,
          'height': mainImage.height
        }}
      >
        <img 
          src={mainImage.asset.fixed.src || ''} 
          alt={mainImage.alt || ''}
        />
        <figcaption>{mainImage.caption}</figcaption>
      </figure>
    )
  }
  return img;
};

const SectionStyle = css`
  margin: 0 0 1em;

  p a {
    color: #333;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &.is-index-0 {
    h1 {
      font-size: 56px;
      @media only screen and (${breakpoints.mobile}) {
        font-size: 36px;
      }
      margin-bottom: .5em;
    }

    p {
      font-size: 24px;
    }
  }

  h2 {
    font-size: 1.3em;
    font-weight: 600;
  }

  h3 {
    font-size: 20px !important;
  }

`

function Section (props) {
  const img = maybeImage(props.mainImage);
  const video = maybeVideo(props.video);

  return (
    <SectionStyle 
      bg={props.bg}
      index={props.index}>

      <section 
        key={props.title}
        className={`${SectionStyle} ${props.index === 0 ? 'is-index-0' : ''}`}
        style={{'background': props.bg, 'marginBottom': props.bg ? 0 : ''}}
      >

      <div className="container">

        {video

        ? <div className="section py-6 medhjelper-editorial-section-with-image">
            <div className="columns is-vcentered">
              <div className="column is-offset-1 is-5">

                <div className="mb-4">
                  {props.title}
                </div>

                {props._rawRichText && 
                  <Body blocks={props._rawRichText} />
                }

                <div className="section">
                  <div className="columns">
                    <div className="column is-offset-1">
                      {(props.links || []).map((link, i) => (
                        <LinkComponent key={i} {...link} arrow />
                      ))}
                    </div>
                  </div>
                </div>

              </div>
              <div className="column is-5">
                {video}
              </div>
            </div>
          </div>
            
          : img ? <div className="section py-6 medhjelper-editorial-section-with-image">
              <div className="columns is-vcentered">
                <div className="column is-offset-1 is-5">

                  <div className="mb-4">
                    {props.title}
                  </div>

                  {props._rawRichText && 
                    <Body blocks={props._rawRichText} />
                  }

                  <div className="section">
                    <div className="columns">
                      <div className="column is-offset-1">
                        {(props.links || []).map((link, i) => (
                          <LinkComponent key={i} {...link} arrow />
                        ))}
                      </div>
                    </div>
                  </div>

                </div>
                <div className="column is-4 is-offset-1">
                  {video || img}
                </div>
              </div>
            </div>

          : <div className={`medhjelper-editorial-section-without-image section ${props.index === 0 && props.displayTitle ? 'py-6' : ''}`}>
              {/* <div className={` ${props.index === 0 && props.displayTitle ? 'py-6' : ''}`}> */}

                {props.title &&
                  <div className="columns">
                    <div className="column is-offset-1 is-8">
                      {props.title}
                    </div>
                  </div>
                }

                <div>
                  {props._rawRichText && <PortableTextWrap 
                    textWidth="is-offset-1 is-7"
                    portableTextContent={props._rawRichText} 
                  />}
                </div>

                {props.links &&
                  <div className="section">
                    <div className="columns has-text-right">
                      <div className="column is-7 is-offset-1">
                        {(props.links || []).map((link, i) => (
                          <LinkComponent 
                            key={i} 
                            slug={link.page.slug.current} 
                            linkText={link.linkText ? link.linkText : 'Les mer'} 
                            chevron 
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                }
                
              {/* </div> */}
            </div>

          }

        </div>
      </section>
      
    </SectionStyle>
  )
}

export default Section;
