import React from "react";
import styled from '@emotion/styled';

import RichText from "./portableText";

import { breakpoints } from "../../styling/breakpoints";

import "../../styling/libraries.scss"
import { colors } from "../../styling/colors";

const SectionStyle = styled.div`

  .columns {
    @media only screen and (${breakpoints.mobile}) {
      display: flex;
      flex-direction: row;
      flex-direction: ${props => props.i && props.i % 2 ? 'column-reverse' : 'column'};
    }
  }

  .hasIconBackground {
    background: ${colors.green03};
    border-radius: 100%;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    display: block;
    text-align: center;
  }
`

const maybeImage = image => {
  let img = null;
  if (image && image.asset && image.asset.fixed && image.asset.fixed.src) {
    img = (
      <img src={image.asset.fixed.src} alt={image.alt ? image.alt : ''} />
    );
  }
  return img;
};

function TextWithIcon (props) {

  const img = maybeImage(props.mainImage);

  return (
    <SectionStyle 
      bg={props.bg}
      i={props.i}
      className="column"
      index={props.index}
      key={props._key}>

        {props.horizonal 
          ? <div className="mb-5" style={{'maxWidth': '748px', 'margin': props.centered ? '0 auto' : ''}}>
              <div className="columns content has-text-left">
                {props.i % 2 || props.iconRight
                  ? <>
                      <div className="column">
                        {props.title && <h3 className="title is-size-5">{props.title}</h3>}
                        {props._rawRichText && <RichText portableTextContent={props._rawRichText} />}
                      </div>
                      <div className={`column is-one-third`}>
                       <div className={`${props.hasIconBackground && 'hasIconBackground' }`}>
                          {img}
                        </div>
                      </div>
                    </>
                  : <>
                      <div className={`column is-one-third`}>
                        <div className={`${props.hasIconBackground && 'hasIconBackground' }`}>
                          {img}
                        </div>
                        </div>
                      <div className="column">
                        {props.title && <h3 className="title is-size-5">{props.title}</h3>}
                        {props._rawRichText && <RichText portableTextContent={props._rawRichText} />}
                      </div>
                    </>
                }
              </div>
            </div>
          : <div className="column has-text-centered">
            
              <div className="icon is-large mb-4">{img}</div>

              {props._rawRichText && <RichText portableTextContent={props._rawRichText} />}

            </div>
        }
    
    </SectionStyle>
  )
}

export default TextWithIcon;
